export default function SubNavBrandIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0287 17.4692C5.06195 18.4352 1.6532 18.3437 1.6532 18.3437C1.6532 18.3437 1.5617 14.935 2.52845 13.969C3.49501 13.0024 5.06213 13.0024 6.0287 13.969C6.99526 14.9356 6.99526 16.5027 6.0287 17.4692Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.82441 14.764L5.23291 13.1732L8.01766 8.00275L16.2609 1.90975C16.7808 1.52509 17.504 1.57901 17.9612 2.0365V2.0365C18.4183 2.49379 18.4722 3.21673 18.0879 3.73675L11.9949 11.98L6.82441 14.764Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
