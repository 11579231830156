import Link from 'next/link'
import { useState } from 'react'
import styles from '@style/MobileNavigation.module.css'
import Header from './header'
import HeaderChevron from '@comp/icons/header-chevron'
import MonosignHeaderNavLogo from '@comp/icons/monosign-header-nav-logo'
import MonopamHeaderNavLogo from './icons/monopam-header-nav-logo'
import MonosyncHeaderNavLogo from './icons/monosync-header-nav-logo'
import SubNavBlogIcon from './icons/subnav-blog-icon'
import SubNavBrandIcon from './icons/subnav-brand-icon'
import SubNavDocsIcon from './icons/subnav-docs-icon'
import headerStyles from '@style/Header.module.css'

interface MobileNavProps {
  toggleFunction: () => void
  isMenuOpen: boolean
  isMini: boolean
}

enum ExpandableSections {
  Products = 1,
  Resources,
  Company,
}

export default function MobileNavigation({
  toggleFunction,
  isMenuOpen,
  isMini = false,
}: MobileNavProps) {
  const [openSubMenu, setOpenSubmenu] = useState(0)

  return (
    <div
      className={`${styles.mobileNavigation} ${
        isMenuOpen == true && `${styles.open}`
      }`}
    >
      <Header
        toggleFunction={toggleFunction}
        isMenuOpen={isMenuOpen}
        isMini={isMini}
      />
      <nav className={headerStyles.mobileNav}>
        <ul>
          <li
            className={`${headerStyles.productsNav} ${
              openSubMenu === 1 && headerStyles.open
            }`}
            onClick={(e) => {
              e.preventDefault()
              setOpenSubmenu(openSubMenu === 1 ? 0 : 1)
            }}
          >
            <a href="#" className={headerStyles.dropdownListLink}>
              Products
              <HeaderChevron />
            </a>
            <div className={headerStyles.subList}>
              <h6>Products</h6>
              <ul className={headerStyles.subListItems}>
                <li className={headerStyles.monosign}>
                  <Link
                    href="/sign"
                    onClick={(e) => {
                      toggleFunction()
                    }}
                  >
                    <div className={headerStyles.subListItemContent}>
                      <MonosignHeaderNavLogo />
                    </div>
                  </Link>
                </li>
                <li className={headerStyles.monopam}>
                  <Link href="/pam">
                    <div className={headerStyles.subListItemContent}>
                      <MonopamHeaderNavLogo />
                    </div>
                  </Link>
                </li>
                <li className={headerStyles.monosync}>
                  <Link href="/sync">
                    <div className={headerStyles.subListItemContent}>
                      <MonosyncHeaderNavLogo />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          {/* <li>
            <a href="#">Solutions</a>
          </li> */}
          <li
            className={`${headerStyles.resourcesNav} ${
              openSubMenu === 2 && headerStyles.open
            }`}
            onClick={(e) => {
              e.preventDefault()
              setOpenSubmenu(openSubMenu === 2 ? 0 : 2)
            }}
          >
            <a href="#" className={headerStyles.dropdownListLink}>
              Resources
              <HeaderChevron />
            </a>
            <div className={headerStyles.subList}>
              <h6>Resources</h6>
              <ul className={headerStyles.subListItems}>
                <li>
                  <Link href="/blog">
                    <div className={headerStyles.subListItemContent}>
                      <SubNavBlogIcon />
                      <div
                        className={headerStyles.subListItemTitleAndDescription}
                      >
                        <p>Blog</p>
                        <span>
                          Articles, How-To&apos;s and Tutorials on all things
                          Identity
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <a href="https://help.monofor.com" target="_blank">
                    <div className={headerStyles.subListItemContent}>
                      <SubNavDocsIcon />
                      <div
                        className={headerStyles.subListItemTitleAndDescription}
                      >
                        <p>Documentation</p>
                        <span>
                          Installation Guides and detailed documentation on all
                          our products
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://brand.monofor.com" target="_blank">
                    <div className={headerStyles.subListItemContent}>
                      <SubNavBrandIcon />
                      <div
                        className={headerStyles.subListItemTitleAndDescription}
                      >
                        <p>Brand Guidelines</p>
                        <span>Access our logos and brand guidelines</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          {/* <li>
            <a href="#">Success Stories</a>
          </li> */}
          <li className={`${headerStyles.companyNav}`}>
            <Link
              href="/company"
              className={headerStyles.dropdownListLink}
              onClick={(e) => {
                toggleFunction()
              }}
            >
              Company
            </Link>
          </li>
        </ul>
        <button className={styles.finalCtaButton}>Start Now</button>
      </nav>
    </div>
  )
}
