export default function SubNavDocsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.6875"
        y="1.5625"
        width="4.5"
        height="12.9375"
        rx="1.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="7.1875"
        y="1.5625"
        width="4.5"
        height="12.9375"
        rx="1.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="12.251"
        y="2.97212"
        width="3.375"
        height="11.8125"
        rx="1.5"
        transform="rotate(-8.267 12.251 2.97212)"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5625 15.625C1.5625 16.2463 2.06618 16.75 2.6875 16.75H17.3125C17.9338 16.75 18.4375 16.2463 18.4375 15.625C18.4375 15.0037 17.9338 14.5 17.3125 14.5H2.6875C2.06618 14.5 1.5625 15.0037 1.5625 15.625Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 16.75V18.4375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 16.75V18.4375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
