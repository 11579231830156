export default function SubNavBlogIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4375 4.5V15.1875C17.4375 16.1195 16.682 16.875 15.75 16.875C14.818 16.875 14.0625 16.1195 14.0625 15.1875V2.25C14.0625 1.62868 13.5588 1.125 12.9375 1.125H1.6875C1.06618 1.125 0.5625 1.62868 0.5625 2.25V15.1875C0.5625 16.1195 1.31802 16.875 2.25 16.875H15.75"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 11.25H11.25"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 13.5H7.875"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="3.375"
        y="3.9375"
        width="7.875"
        height="4.5"
        rx="0.5625"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
