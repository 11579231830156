import Footer from '@comp/footer'
import Header from '@comp/header'
import React from 'react'
import { useState } from 'react'
import Head from 'next/head'
import MobileNavigation from './mobile-navigation'

type LayoutProps = {
  children: any
  miniMode?: boolean
}

export default function Layout({ children, miniMode = false }: LayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <Head>
        <meta
          content="IAM, PAM and IGA products that cover all your 
          identity needs."
          name="description"
        />
        <meta
          content="Monofor – Empowered Identity and Access"
          property="og:title"
        />
        <meta
          content="IAM, PAM and IGA products that cover all your 
          identity needs."
          property="og:description"
        />
        <meta content="/images/ogImage.jpg" property="og:image" />
        <meta
          content="Monofor – Empowered Identity and Access"
          property="twitter:title"
        />
        <meta
          content="IAM, PAM and IGA products that cover all your 
          identity needs."
          property="twitter:description"
        />
        <meta content="/images/ogImage.jpg" property="twitter:image" />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
      </Head>
      <Header
        toggleFunction={() => {
          setIsMenuOpen(!isMenuOpen)
        }}
        isMini={miniMode}
        isMenuOpen={isMenuOpen}
      />
      <MobileNavigation
        toggleFunction={() => {
          setIsMenuOpen(!isMenuOpen)
        }}
        isMini={miniMode}
        isMenuOpen={isMenuOpen}
      />
      {children}
      <Footer />
    </>
  )
}
