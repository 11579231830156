import Link from 'next/link'

export default function HeaderLogo() {
  return (
    <Link href="/">
      <svg
        width="143"
        height="29"
        viewBox="0 0 143 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1292_12933"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="30"
          height="29"
        >
          <path d="M29.9267 0H0V28.5511H29.9267V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1292_12933)">
          <path
            d="M7.74928 4.05033C8.13139 3.92806 8.5135 3.80578 8.91089 3.69879C10.8214 3.17912 12.839 2.88872 14.9176 2.88872C16.9963 2.88872 19.0138 3.16384 20.9244 3.69879C21.3217 3.80578 21.7039 3.92806 22.086 4.05033C22.7432 4.26431 23.3698 4.50886 23.9965 4.78398C25.0358 5.24251 26.0293 5.76217 26.9769 6.37354C26.9464 5.6399 26.8852 4.92154 26.7935 4.20317C26.7324 3.75993 26.656 3.31668 26.5795 2.87344C25.5249 2.3232 24.4245 1.86467 23.2934 1.46728C22.6056 1.22273 21.9025 1.02404 21.1995 0.840627C20.7868 0.733637 20.3588 0.641928 19.9309 0.550222C18.3107 0.229251 16.6295 0.0611267 14.9176 0.0611267C13.2058 0.0611267 11.5245 0.229251 9.90437 0.550222C9.47641 0.641928 9.04845 0.733637 8.63577 0.840627C7.91741 1.02404 7.22961 1.23802 6.54182 1.46728C5.41078 1.86467 4.31031 2.3232 3.25569 2.87344C3.16399 3.31668 3.08757 3.75993 3.02643 4.20317C2.93472 4.92154 2.87359 5.65518 2.84302 6.37354C3.79064 5.77746 4.78412 5.24251 5.82346 4.78398C6.4654 4.52414 7.09205 4.2796 7.74928 4.05033Z"
            fill="white"
          />
          <path
            d="M28.7804 7.62688C28.1996 7.18364 27.6036 6.75567 26.9922 6.37357C27.038 7.48932 26.9922 8.62036 26.8546 9.73611C26.7782 10.4086 26.6712 11.0811 26.5337 11.7536C26.4572 12.151 26.3502 12.5331 26.2585 12.9305C25.8 14.6882 25.1122 16.4001 24.1952 18.0661C23.1253 20.0072 21.7955 21.719 20.2977 23.2016C20.0073 23.492 19.7169 23.7671 19.4112 24.0269C18.8915 24.4855 18.3565 24.9134 17.8063 25.3108C16.8893 25.9833 15.9263 26.5794 14.9329 27.0991C15.5901 27.4353 16.2473 27.741 16.9351 28.0161C17.3478 28.1843 17.7757 28.3371 18.2037 28.49C19.2125 27.848 20.1601 27.1297 21.0772 26.3502C21.6274 25.8763 22.1623 25.372 22.6667 24.8523C22.9724 24.5466 23.2628 24.2256 23.5532 23.8894C24.7301 22.5444 25.7847 21.0618 26.7018 19.4264C27.4354 18.1119 28.0315 16.7516 28.5053 15.376C28.6429 14.9633 28.7804 14.5507 28.9027 14.138C29.1014 13.4349 29.2695 12.7318 29.4071 12.0135C29.6364 10.8366 29.7892 9.65969 29.8351 8.4828C29.4835 8.1924 29.132 7.902 28.7804 7.62688Z"
            fill="white"
          />
          <path
            d="M10.4393 24.0269C10.1336 23.7671 9.84319 23.492 9.55279 23.2016C8.19249 21.8719 6.98502 20.3281 5.96098 18.6163C4.87579 16.7975 4.09629 14.8869 3.57662 12.9305C3.46963 12.5484 3.37793 12.151 3.30151 11.7536C3.16395 11.0811 3.05696 10.4086 2.98054 9.73611C2.85826 8.62036 2.81241 7.48932 2.84298 6.37357C2.23161 6.77096 1.62023 7.18364 1.05471 7.62688C0.703175 7.902 0.351636 8.1924 0.0153809 8.4828C0.0765181 9.65969 0.214077 10.8519 0.443342 12.0135C0.580901 12.7318 0.749026 13.4349 0.947722 14.138C1.07 14.5507 1.19227 14.9633 1.34512 15.376C1.89535 16.9809 2.61371 18.5399 3.51549 20.053C4.34084 21.4439 5.27318 22.7278 6.29723 23.8894C6.58763 24.2104 6.87804 24.5313 7.18372 24.837C7.70339 25.3567 8.22305 25.8611 8.77329 26.3349C9.69035 27.1144 10.6533 27.8327 11.6467 28.4747C12.0747 28.3371 12.5027 28.169 12.9153 28.0009C13.5878 27.7257 14.2604 27.4201 14.9176 27.0838C13.9241 26.5641 12.9612 25.9681 12.0441 25.2955C11.4939 24.9134 10.9589 24.4855 10.4393 24.0269Z"
            fill="white"
          />
          <path
            d="M21.6884 16.6905C22.6819 14.8869 23.3697 12.9917 23.7671 11.0659C23.8435 10.699 23.9046 10.3169 23.9658 9.93481C24.0575 9.29287 24.1186 8.65093 24.1492 8.00899C23.5837 7.7033 22.9876 7.42819 22.3762 7.18364C22.0247 7.04608 21.6578 6.90852 21.3063 6.78624C19.3041 6.11374 17.1643 5.73163 14.9327 5.73163C12.7012 5.73163 10.5614 6.09845 8.55919 6.78624C8.19237 6.90852 7.84083 7.04608 7.48929 7.18364C6.87792 7.42819 6.29711 7.7033 5.71631 8.00899C5.74688 8.65093 5.80801 9.29287 5.89972 9.93481C5.96086 10.3169 6.02199 10.6837 6.09842 11.0659C6.52638 13.1598 7.30588 15.2232 8.4522 17.1643C9.55267 19.0137 10.913 20.6338 12.4567 21.9788C12.7471 22.2387 13.0375 22.4679 13.3432 22.7125C13.8628 23.1099 14.3978 23.4767 14.948 23.8282C15.4983 23.492 16.0332 23.1099 16.5529 22.7125C16.8586 22.4832 17.149 22.2387 17.4394 21.9788C19.0901 20.5116 20.5421 18.7386 21.6884 16.6905Z"
            fill="white"
          />
        </g>
        <path
          d="M39.9075 23.385V6.00674H44.936L49.185 18.9678H49.7506L54.0455 6.00674H59.0587V23.385H55.956V10.8672H55.3752L51.2484 23.385H47.7178L43.591 10.8672H42.9949V23.385H39.9075Z"
          fill="white"
        />
        <path
          d="M73.7009 10.5003C72.3253 9.09415 70.5676 8.37579 68.4431 8.37579C66.3033 8.37579 64.5456 9.07887 63.1547 10.5003C61.7791 11.9065 61.0913 13.7559 61.0913 16.0332C61.0913 18.3106 61.7791 20.1447 63.1547 21.5661C64.5456 22.9723 66.3185 23.6907 68.4431 23.6907C70.5829 23.6907 72.3406 22.9876 73.7009 21.5661C75.0917 20.16 75.7948 18.3106 75.7948 16.0332C75.7948 13.7559 75.0917 11.9218 73.7009 10.5003ZM71.5916 19.6862C70.7663 20.588 69.7117 21.0465 68.4431 21.0465C67.1745 21.0465 66.1046 20.588 65.2639 19.6862C64.4386 18.7691 64.0259 17.5464 64.0259 16.0332C64.0259 14.5201 64.4386 13.3126 65.2639 12.4108C66.1046 11.4938 67.1592 11.0353 68.4431 11.0353C69.7117 11.0353 70.7663 11.4938 71.5916 12.4108C72.4323 13.3126 72.8602 14.5201 72.8602 16.0332C72.8449 17.5464 72.4323 18.7538 71.5916 19.6862Z"
          fill="white"
        />
        <path
          d="M77.7053 23.385V8.68148H80.3495L80.6399 10.1488H80.7928C81.129 9.81252 81.4958 9.52211 81.8779 9.26228C82.8256 8.68148 83.8802 8.37579 85.0571 8.37579C86.8453 8.37579 88.2668 8.94131 89.3214 10.0571C90.4066 11.1575 90.9415 12.6554 90.9415 14.5507V23.3697H88.007V14.5659C88.007 13.603 87.6707 12.7777 86.9829 12.0899C86.3104 11.3868 85.485 11.0353 84.4763 11.0353C83.3911 11.0353 82.4893 11.3868 81.7404 12.0899C81.022 12.7777 80.6552 13.603 80.6552 14.5659V23.385H77.7053Z"
          fill="white"
        />
        <path
          d="M105.63 10.5003C104.254 9.09415 102.497 8.37579 100.372 8.37579C98.2322 8.37579 96.4745 9.07887 95.0836 10.5003C93.7081 11.9065 93.0203 13.7559 93.0203 16.0332C93.0203 18.3106 93.7081 20.1447 95.0836 21.5661C96.4745 22.9723 98.2475 23.6907 100.372 23.6907C102.512 23.6907 104.27 22.9876 105.63 21.5661C107.021 20.16 107.724 18.3106 107.724 16.0332C107.724 13.7559 107.021 11.9218 105.63 10.5003ZM103.521 19.6862C102.695 20.588 101.641 21.0465 100.372 21.0465C99.1034 21.0465 98.0335 20.588 97.1929 19.6862C96.3675 18.7691 95.9549 17.5464 95.9549 16.0332C95.9549 14.5201 96.3675 13.3126 97.1929 12.4108C98.0335 11.4938 99.0881 11.0353 100.372 11.0353C101.641 11.0353 102.695 11.4938 103.521 12.4108C104.361 13.3126 104.789 14.5201 104.789 16.0332C104.789 17.5464 104.361 18.7538 103.521 19.6862Z"
          fill="white"
        />
        <path
          d="M116.711 5.59404H114.495C113.914 5.59404 113.608 5.88445 113.608 6.48054V8.68147H116.696V11.4785H113.608V23.385H110.674V11.4785H108.32V8.68147H110.674V6.03729C110.674 5.1508 110.964 4.41715 111.56 3.83635C112.141 3.25554 112.89 2.94986 113.761 2.94986H116.696"
          fill="white"
        />
        <path
          d="M129.932 10.5003C128.556 9.09415 126.799 8.37579 124.674 8.37579C122.534 8.37579 120.777 9.07887 119.386 10.5003C118.01 11.9065 117.322 13.7559 117.322 16.0332C117.322 18.3106 118.01 20.1447 119.386 21.5661C120.777 22.9723 122.55 23.6907 124.674 23.6907C126.814 23.6907 128.572 22.9876 129.932 21.5661C131.323 20.16 132.026 18.3106 132.026 16.0332C132.01 13.7559 131.323 11.9218 129.932 10.5003ZM127.807 19.6862C126.982 20.588 125.927 21.0465 124.659 21.0465C123.39 21.0465 122.32 20.588 121.48 19.6862C120.654 18.7691 120.242 17.5464 120.242 16.0332C120.242 14.5201 120.654 13.3126 121.48 12.4108C122.32 11.4938 123.375 11.0353 124.659 11.0353C125.927 11.0353 126.982 11.4938 127.807 12.4108C128.648 13.3126 129.076 14.5201 129.076 16.0332C129.076 17.5464 128.648 18.7538 127.807 19.6862Z"
          fill="white"
        />
        <path
          d="M143 11.3257H140.57C138.506 11.3257 137.482 12.3497 137.482 14.3825V23.385H134.548V8.68149H137.054L137.345 10.0112H137.498C137.513 9.99594 137.574 9.91952 137.681 9.81253C137.803 9.69025 137.865 9.61383 137.88 9.58326C137.926 9.53741 138.002 9.47627 138.109 9.38457C138.231 9.26229 138.323 9.18587 138.399 9.1553C138.476 9.10945 138.583 9.0636 138.72 9.00246C138.858 8.92604 138.996 8.88018 139.133 8.84962C139.286 8.80376 139.469 8.78848 139.668 8.75791C139.867 8.71206 140.065 8.69677 140.279 8.69677H142.924"
          fill="white"
        />
      </svg>
    </Link>
  )
}
