import HeaderChevron from './icons/header-chevron'
import HeaderLogo from './icons/header-logo'
import styles from '@style/Header.module.css'
import SubNavBlogIcon from './icons/subnav-blog-icon'
import SubNavDocsIcon from './icons/subnav-docs-icon'
import SubNavBrandIcon from './icons/subnav-brand-icon'
import Link from 'next/link'
import MonosignHeaderNavLogo from './icons/monosign-header-nav-logo'
import MonopamHeaderNavLogo from './icons/monopam-header-nav-logo'
import MonosyncHeaderNavLogo from './icons/monosync-header-nav-logo'
import MenuIcon from './icons/menu-icon'
import CloseIcon from './icons/close-icon'

import { useGlobalContext } from '../context/GlobalContext'

interface HeaderProps {
  isMenuOpen: boolean
  isMini: boolean
  toggleFunction: () => void
}

export default function Header({
  isMenuOpen,
  isMini = false,
  toggleFunction,
}: HeaderProps) {
  const { globalData } = useGlobalContext()
  return (
    <>
      <header className={`${styles.headerContainer} ${isMini && styles.mini}`}>
        <div className={styles.leftNav}>
          <div className={styles.logo}>
            <HeaderLogo />
          </div>
          <nav>
            <ul className={styles.leftNavList}>
              <li className={styles.productsNav}>
                <a href="#">
                  Products
                  <HeaderChevron />
                </a>
                <div className={styles.subList}>
                  <h6>Products</h6>
                  <ul className={styles.subListItems}>
                    <Link href="/sign">
                      <li className={styles.monosign}>
                        <div className={styles.subListItemContent}>
                          <MonosignHeaderNavLogo />
                        </div>
                      </li>
                    </Link>
                    <Link href="/pam">
                      <li className={styles.monopam}>
                        <div className={styles.subListItemContent}>
                          <MonopamHeaderNavLogo />
                        </div>
                      </li>
                    </Link>
                    <Link href="/sync">
                      <li className={styles.monosync}>
                        <div className={styles.subListItemContent}>
                          <MonosyncHeaderNavLogo />
                        </div>
                      </li>
                    </Link>
                  </ul>
                </div>
              </li>
              <li className={styles.resourcesNav}>
                <a href="#">
                  Features
                  <HeaderChevron />
                </a>
                <div className={styles.subList}>
                  <h6>Features</h6>
                  <ul className={styles.subListItems}>
                    {globalData.features &&
                      globalData.features.map((feature: any, index: number) => (
                        <>
                          <li>
                            <Link href={'/features/' + feature.slug}>
                              <div className={styles.subListItemContent}>
                                <div
                                  className={
                                    styles.subListItemTitleAndDescription
                                  }
                                >
                                  <p>{feature.title}</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </>
                      ))}
                  </ul>
                </div>
              </li>
              <li className={styles.resourcesNav}>
                <a href="#">
                  Resources
                  <HeaderChevron />
                </a>
                <div className={styles.subList}>
                  <h6>Resources</h6>
                  <ul className={styles.subListItems}>
                    <li>
                      <Link href="/blog">
                        <div className={styles.subListItemContent}>
                          <SubNavBlogIcon />
                          <div
                            className={styles.subListItemTitleAndDescription}
                          >
                            <p>Blog</p>
                            <span>
                              Articles, How-To&apos;s and Tutorials on all
                              things Identity
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <a href="https://help.monofor.com">
                        <div className={styles.subListItemContent}>
                          <SubNavDocsIcon />
                          <div
                            className={styles.subListItemTitleAndDescription}
                          >
                            <p>Documentation</p>
                            <span>
                              Installation Guides and detailed documentation on
                              all our products
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="https://brand.monofor.com">
                        <div className={styles.subListItemContent}>
                          <SubNavBrandIcon />
                          <div
                            className={styles.subListItemTitleAndDescription}
                          >
                            <p>Brand Guidelines</p>
                            <span>Access our logos and brand guidelines</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {/* <li>
                <a href="#">Success Stories</a>
            </li> */}
              <li>
                <Link href="/company">Company</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.rightNav}>
          <button
            className={styles.menuToggleButton}
            onClick={(e) => {
              e.preventDefault()
              toggleFunction()
            }}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
          <Link href="/try/now">
            <button className={styles.headerButton}>Start Now</button>
          </Link>
        </div>
      </header>
    </>
  )
}
